<script>
    export default {
        name: 'SodaTermsNl',
        props: {
            isDirectCustomer: Boolean,
        },
    };
</script>

<template>
    <div>
        <h3 class='cb-service-main-title'>
            Beschrijving van de dienst
        </h3>

        <p>
            De SODA-dienst van Codabox houdt in dat Codabox de loonboekingen van de verschillende sociale secretariaten in een gestandaardiseerd digitaal XML-formaat (ontwikkeld en geregistreerd door Codabox) giet, waardoor je ze eenvoudig rechtstreeks in je boekhoudsoftware kan importeren.
            Je ontvangt ook analytische informatie en eventuele correcties.
            Dankzij deze automatisering behoort het verzamelen en individueel coderen van de boekhoudkundige documenten die het sociaal secretariaat apart verstuurt tot het verleden.
        </p>

        <p>
            <a href='https://www.codabox.com/diensten/soda/de-sociale-secretariaten-partners/' target='_blank'>Overzicht van de aangesloten sociale secretariaten </a>
        </p>

        <p>
            De SODA-bestanden worden op regelmatige basis in je boekhoudsoftware geïmporteerd als diverse verrichtingen.
            Merk op dat de SODA-bestanden bedoeld zijn voor de boekhoudkundige uitsplitsing en dus geen facturatiegegevens bevatten tussen het sociaal secretariaat en de onderneming.
        </p>

        <p>
            <a href='https://www.codabox.com/diensten/soda/software-klaar-voor-integratie/' target='_blank'>Overzicht van de compatibele boekhoudsoftware</a>
        </p>

        <p>
            Via MyCodabox kan je SODA bestellen en de status van je aanvragen opvolgen.
            Om SODA te activeren, moet er voor de onderneming (dossier) minstens één actief CODA-mandaat zijn.
        </p>

        <h3>Tarieven</h3>

        <h4 class='cb-service-title'>
            Administratieve kosten
        </h4>

        <p v-if='isDirectCustomer'>
            24 € per SODA-abonnement (na ontvangst van eerste bestand)
        </p>
        <p v-else>
            Zodra een SODA-mandaat geactiveerd is, wordt, na de eerste ontvangst van een bestand voor dit mandaat, een eenmalig bedrag van 6,00 € per SODA-mandaat gefactureerd.
        </p>

        <h4 class='cb-service-title'>
            Recurrente kosten
        </h4>

        <template v-if='isDirectCustomer'>
            <table class='table'>
                <tbody>
                    <tr>
                        <td>> 2 actieve SODA abonnementen</td>
                        <td>7,53 €/maand/abonnement</td>
                    </tr>
                    <tr>
                        <td>1 - 2 actieve SODA abonnementen</td>
                        <td>9,37 €/maand/abonnement</td>
                    </tr>
                </tbody>
            </table>
            <p>
                Beheer je onder één Codabox-contract meerdere ondernemingen, dan bepaalt het totaal aantal SODA-abonnementen het tarief voor de recurrente kosten.
            </p>
            <p class='note'>
                <span class='fa fa-info-circle'></span>
                SODA blijft actief tot je via MyCodabox de dienst archiveert, of het mandaat wordt stopgezet.
            </p>
        </template>

        <p v-if='!isDirectCustomer'>
            Vanaf de activatie van de SODA-dienst voor jouw boekhoudkantoor wordt maandelijks een forfaitair bedrag van 67,00 € gefactureerd, ongeacht het aantal bestelde SODA-mandaten.
        </p>

        <h3>Algemene voorwaarden</h3>
        <p v-if='isDirectCustomer'>
            <a href='/static/pdfs/20240901 Overeenkomst CodaBox Business CODA-SODA-CARO_NL.pdf' target='_blank'>Overeenkomst Codabox Business</a>
        </p>
        <p v-else>
            <a href='/static/pdfs/MyCodaBox.com 202401 CB-Fidu NL-Annex SODA (B2-C2-D).pdf' target='_blank'>Algemene voorwaarden van de dienst</a>
        </p>
    </div>
</template>

<style lang='scss' scoped>
    h3.cb-service-main-title {
        margin-top: 10px;
    }
    h4.cb-service-title {
        text-decoration: underline;
    }
    p.note {
        font-style: italic;
    }
    p.note .fa-info-circle {
        font-size: 16px;
    }
    p, table {
        margin: 10px 0;
    }
    table {
        table-layout: fixed;
    }
    tbody {
        color: $primary-color-dark;
    }
</style>
