<script>
    export default {
        name: 'PurchaseTermsNl',
    };
</script>

<template>
    <div>
        <h3 class='cb-service-main-title'>
            Beschrijving van de dienst
        </h3>

        <p>Met de dienst VOILA kan je voortaan de aankoopfacturen van je klanten, verzonden via verschillende kanalen (Peppol, Zoomit, …), in elektronisch formaat ontvangen. Je klant hoeft jou deze aankoopfacturen dan niet langer te bezorgen.</p>

        <p>Je kan de dienst VOILA activeren via het menu ‘Volmachten & diensten > VOILA' in MyCodabox. Dit kan voor al je klanten in één keer of voor elke klant afzonderlijk en vraagt slechts enkele muisklikken!</p>

        <p>BELANGRIJK: Informeer je klanten dat ze door Codabox gecontacteerd zullen worden om de VOILA dienst te aanvaarden. Dit is belangrijk om het succes van de inschrijving op deze dienst te verhogen.</p>

        <p>Hoe werkt deze dienst voor je klanten?</p>

        <ol>
            <li>Wanneer je de dienst voor je klanten bestelt, zullen zij via e-mail gevraagd worden de dienst VOILA te aanvaarden.</li>
            <li>Je klanten worden door Codabox geïnformeerd over de volgende stappen na het aanvaarden van deze dienst, en wat dit voor hen betekent.</li>
            <li>Zodra een klant de dienst heeft aanvaard, zal Codabox deze bevestiging gebruiken om je klant in te schrijven op de verschillende netwerken voor elektronische facturatie.</li>
            <li>Je klant zal de facturen die hij via deze kanalen ontvangt niet meer zelf aan jou moeten bezorgen. Zodra een leverancier een factuur verzendt zal Codabox deze aan jouw klant bezorgen via de gekozen leveringswijze (jouw boekhoudkundig klantenportaal of e-mail). Jij beschikt meteen over zijn elektronische facturen die Codabox je bezorgt via dezelfde weg als voor de ontvangst van CODA.</li>
        </ol>

        <h3>Tarieven</h3>

        <h4 class='cb-service-title'>
            Administratieve kosten
        </h4>

        <p>Er worden geen administratieve kosten aangerekend.</p>

        <h4 class='cb-service-title'>
            Recurrente kosten
        </h4>

        <p>Tot nader order past Codabox een korting toe van 65% op de vermelde standaardprijzen. Als Codabox deze korting zou verminderen of intrekken, zal Codabox je hiervan minstens drie maanden op voorhand inlichten.</p>
        <table class='table'>
            <tbody>
                <tr>
                    <td>+ 500 ondernemingsnummers</td>
                    <td>3,62 €/maand/ondernemingsnummer</td>
                </tr>
                <tr>
                    <td>Tussen 241 en 500 ondernemingsnummers</td>
                    <td>4,53 €/maand/ondernemingsnummer</td>
                </tr>
                <tr>
                    <td>Tussen 121 en 240 ondernemingsnummers</td>
                    <td>5,01 €/maand/ondernemingsnummer</td>
                </tr>
                <tr>
                    <td>Tussen 61 en 120 ondernemingsnummers</td>
                    <td>6,28 €/maand/ondernemingsnummer</td>
                </tr>
                <tr>
                    <td>Tussen 3 en 60 ondernemingsnummers</td>
                    <td>7,53 €/maand/ondernemingsnummer</td>
                </tr>
                <tr>
                    <td>Voor 1 en 2 ondernemingsnummers</td>
                    <td>9,37 €/maand/ondernemingsnummer</td>
                </tr>
            </tbody>
        </table>
        <p>
            Voor de bepaling van het correcte tarief wordt ook rekening gehouden met het aantal geactiveerde diensten voor CODA en CARO. Meer details kan je vinden in de Algemene Voorwaarden, via de link hieronder.
        </p>
        <p class='note'>
            <span class='fa fa-info-circle'></span>
            Codabox kan niet bepalen wanneer de dienst CODA, CARO of VOILA niet meer nodig is voor een bepaalde klant, kredietkaart of bankrekening,
            bijvoorbeeld wanneer een rekening gesloten wordt of er geen transacties meer op gebeuren. De dienst blijft actief
            (en blijft deel uitmaken van de berekening van de recurrente kosten) tot je deze zelf
            <a href='https://faq.codabox.com/nl/support/solutions/articles/75000087558-hoe-een-dienst-coda-soda-voila-caro-deactiveren-' target='_blank'>via MyCodabox deactiveert</a>.
        </p>

        <h3>Algemene Voorwaarden</h3>

        <p>
            <a href='/static/pdfs/MyCodaBox.com 202401 CB-Fidu NL-Annex VOILA (B3-C3-D).pdf' target='_blank'>Algemene voorwaarden van de dienst</a>
        </p>
    </div>
</template>

<style lang='scss' scoped>
    .hl { color: rgb(153,204,0); }

    h3.cb-service-main-title {
        margin-top: 10px;
    }

    h4.cb-service-title {
        text-decoration: underline;
    }
    p, table {
        margin: 10px 0;
    }
    table {
        table-layout: fixed;
    }
    ol, tbody {
        color: $primary-color-dark;
    }
</style>
