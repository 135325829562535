import { createI18n } from 'vue-i18n';
import en from './locales/en/translation.json';
import fr from './locales/fr/translation.json';
import nl from './locales/nl/translation.json';

const SUPPORTED_LOCALES = {
    'fr': 'fr_FR',
    'nl': 'nl_BE',
    'en': 'en_US',
};

const DEFAULT_LOCALE = 'en_US';

function simpleLocale (locale) {
    return locale.split('_')[0].split('-')[0];
}

function fullLocale (locale) {
    return SUPPORTED_LOCALES[simpleLocale(locale)] || DEFAULT_LOCALE;
}

function detectBrowserLocale () {
    return fullLocale(window.navigator.language);
}

function loadSavedLocale (defaultLocale = detectBrowserLocale()) {
    return localStorage.getItem('locale') || defaultLocale;
}

const messages = {
    [SUPPORTED_LOCALES.en]: en,
    [SUPPORTED_LOCALES.fr]: fr,
    [SUPPORTED_LOCALES.nl]: nl,
};

const i18n = createI18n({
    legacy: false,
    locale: loadSavedLocale(),
    fallbackLocale: DEFAULT_LOCALE,
    messages,
});

function setLocale (locale) {
    if (!locale) return;

    const selectedLocale = fullLocale(locale);
    localStorage.setItem('locale', selectedLocale);
    i18n.global.locale.value = selectedLocale;
}

function routeLanguageDetection (router) {
    router.beforeEach((to, _, next) => {
        const langParam = to.query.lang;
        if (langParam) {
            setLocale(langParam);
        }
        next();
    });
}

const t = i18n.global.t;

export {
    i18n,
    t,
    loadSavedLocale,
    setLocale,
    simpleLocale,
    fullLocale,
    routeLanguageDetection,
    SUPPORTED_LOCALES,
    DEFAULT_LOCALE,
};
