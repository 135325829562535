<script>
    export default {
        name: 'CodaTermsNl',
        props: {
            hasCodaOnlyContract: Boolean,
            isDirectCustomer: Boolean,
        },
    };
</script>

<template>
    <div>
        <h3 class='cb-service-main-title'>
            Beschrijving van de dienst
        </h3>
        <p>
            Codabox bezorgt je elke dag elektronische rekeninguittreksels in CODA-formaat, rechtstreeks in je
            boekhoudsoftware. Zo hoef je deze rekeninginformatie niet meer zelf te gaan opvragen, en heb je steeds een
            makkelijk én actueel overzicht van de financiële gegevens voor de boekhouding.
        </p>
        <p>
            Via MyCodabox kan je CODA bestellen voor de verschillende rekeningen bij verschillende banken, en dat voor
            alle dossiers waarvoor je de boekhouding voert. Wij nemen dan het verdere administratieve werk op: het
            opmaken van de correcte bankmandaten, ondertekening door de juiste persoon, de opvolging met de banken, enz.
            Jij kan de status van de aanvragen opvolgen via MyCodabox.
        </p>
        <p>
            Codabox ontvangt het eerste CODA-bestand zodra de bank het mandaat heeft gevalideerd en geactiveerd, en er
            een eerste transactie heeft plaatsgevonden op de rekening. Vervolgens controleren wij de nummering, de
            versie en het saldo, maken we een pdf-bestand op met de CODA-gegevens en bezorgen je dagelijks de
            CODA-bestanden in een formaat en structuur die compatibel zijn met jouw boekhoudprogramma.
        </p>
        <h3>Tarieven</h3>

        <h4 class='cb-service-title'>
            Administratieve kosten
        </h4>

        <p>Mandaten: 24 € per mandaat</p>

        <p v-if='!isDirectCustomer'>
            Optionele dienst - Levering van CODA-bestanden aan je klant via SFTP: 100 € per SFTP-server (<a href='https://faq.codabox.com/nl/support/solutions/articles/75000087150-coda-ook-geleverd-aan-je-klant#link' target='_blank'>meer info</a>)
        </p>

        <h4 class='cb-service-title'>
            Recurrente kosten
        </h4>

        <table class='table' v-if='isDirectCustomer'>
            <tbody>
                <tr>
                    <td>> 2 zichtrekeningen</td>
                    <td>7,53 €/maand/rekening</td>
                </tr>
                <tr>
                    <td>1 - 2 zichtrekeningen</td>
                    <td>9,37 €/maand/rekening</td>
                </tr>
            </tbody>
        </table>
        <table class='table' v-else>
            <tbody>
                <tr>
                    <td>+ 500 zichtrekeningen</td>
                    <td>3,62 €/maand/rekening</td>
                </tr>
                <tr>
                    <td>Tussen 241 en 500 zichtrekeningen</td>
                    <td>4,53 €/maand/rekening</td>
                </tr>
                <tr>
                    <td>Tussen 121 en 240 zichtrekeningen</td>
                    <td>5,01 €/maand/rekening</td>
                </tr>
                <tr>
                    <td>Tussen 61 en 120 zichtrekeningen</td>
                    <td>6,28 €/maand/rekening</td>
                </tr>
                <tr>
                    <td>Tussen 3 en 60 zichtrekeningen</td>
                    <td>7,53 €/maand/rekening</td>
                </tr>
                <tr>
                    <td>Voor 1 en 2 zichtrekeningen</td>
                    <td>9,37 €/maand/rekening</td>
                </tr>
            </tbody>
        </table>

        <p v-if='isDirectCustomer'>
            Beheer je onder één Codabox-contract meerdere ondernemingen, dan bepaalt het totaal aantal zichtrekeningen het tarief voor de recurrente kosten.
        </p>
        <p v-else>
            Voor de bepaling van het correcte tarief wordt ook rekening gehouden met het aantal geactiveerde diensten voor CARO en VOILA.
            Meer details kan je vinden in de Algemene Voorwaarden, via de link hieronder.
        </p>
        <p class='note' v-if='isDirectCustomer'>
            <span class='fa fa-info-circle'></span>
            CODA blijft actief tot je via MyCodabox de dienst voor een bepaalde rekening archiveert, of het mandaat wordt stopgezet.
        </p>
        <p class='note' v-else>
            <span class='fa fa-info-circle'></span>
            Codabox kan niet bepalen wanneer de dienst CODA, CARO of VOILA niet meer nodig is voor een bepaalde klant, kredietkaart of bankrekening,
            bijvoorbeeld wanneer een rekening gesloten wordt of er geen transacties meer op gebeuren. De dienst blijft actief
            (en blijft deel uitmaken van de berekening van de recurrente kosten) tot je deze zelf
            <a href='https://faq.codabox.com/nl/support/solutions/articles/75000087558-hoe-een-dienst-coda-soda-voila-caro-deactiveren-' target='_blank'>via MyCodaBox deactiveert</a>.
        </p>

        <template v-if='!isDirectCustomer'>
            <h3>Algemene voorwaarden</h3>
            <p>
                <a href='/static/pdfs/MyCodaBox.com 202401 CB-Fidu NL-Annex CODA (B1-C1-D).pdf' target='_blank'>Algemene voorwaarden van de dienst</a>
            </p>
        </template>
    </div>
</template>

<style lang='scss' scoped>
    h3.cb-service-main-title {
        margin-top: 10px;
    }

    h4.cb-service-title {
        text-decoration: underline;
    }

    .cb-service-info-highlight {
        font-style: italic;
        font-weight: bold;
    }
    p.note {
        font-style: italic;
    }
    p.note .fa-info-circle {
        font-size: 16px;
    }
    p, table {
        margin: 10px 0;
    }
    table {
        table-layout: fixed;
    }
    tbody {
        color: $primary-color-dark;
    }
</style>
