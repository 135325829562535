<template>
    <div>
        <div class='flex items-end mt-6 mb-6'>
            <div>
                <CustomTitle class='m-0'>
                    {{ $t('ttl-organization-connections') }}
                </CustomTitle>
            </div>
        </div>
        <div class='flex mt-6 mb-6'>
            <p class='text-gray-500'>
                {{ $t('txt-sync-connection-info') }} <span class='font-bold'>{{ organization.enterpriseName }}</span>.
            </p>
        </div>
        <ContentBox class='banner-download'>
            <div class='grid grid-cols-2 gap-10 ml-4' style='grid-template-columns: auto minmax(0, 1fr);'>
                <div>
                    <h1 class='mt-0 text-2xl'>
                        {{ $t('lbl-sync-download-the-synctool-banner') }}
                    </h1>
                    <p>
                        <!-- The CodaBox SyncTool lets you download and save files from CodaBox onto your computer.<br>
                        Connections give access to files from companies in this environment.<br>
                        If you have multiple environment, then you'll have multiple connections. -->
                        {{ $t ('txt-sync-download-the-synctool-banner1') }}<br>
                        {{ $t ('txt-sync-download-the-synctool-banner2') }}<br>
                        {{ $t ('txt-sync-download-the-synctool-banner3') }}
                    </p>
                </div>
                <span style='display: block; text-align: right; padding-right: 10%; align-self: center;'>
                    <a :href='downloadLink' target='_blank'>
                        <FriendlyButton
                            symbol='windows'
                            extra-small
                            no-margin
                            square
                            secondary
                            white
                            label='btn-download'
                            fullwidth
                            style='width: 114px;'
                        />
                    </a>
                    <br>
                    <!-- BUTTONS WILL BE USED ONCE WE'LL HAVE A MAC AND LINUX VERSION -->
                    <!--
                    <FriendlyButton
                        extra-small
                        no-margin
                        square
                        secondary
                        white
                        label='btn-download'
                        symbol='apple'
                        style='width: 114px;'
                        fullwidth
                    />
                    <br>
                    <FriendlyButton
                        extra-small
                        no-margin
                        square
                        secondary
                        white
                        label='btn-download'
                        symbol='linux'
                        style='width: 114px;'
                        fullwidth
                    /> -->
                </span>
            </div>
        </ContentBox>
        <br>
        <ContentBox>
            <List :loading='loading'>
                <template #columns>
                    <ListColumn>{{ $t('lbl-sync-connection-name') }}</ListColumn>
                    <ListColumn>{{ $t('lbl-sync-connection-status') }}</ListColumn>
                    <ListColumn>{{ $t('lbl-sync-connection-last-synced') }}</ListColumn>
                    <ListColumn>{{ $t('lbl-sync-connection-user-agent') }}</ListColumn>
                    <ListColumn>{{ $t('col-organization-consents-actions') }}</ListColumn>
                </template>
                <template #rows>
                    <ListRow v-for='(pod, i) in organization?.syncToolConnections' :key='i'>
                        <ListItem>
                            {{ pod.name }}
                        </ListItem>
                        <ListItem>
                            <StatePill v-if='getConnectionStatus(pod.lastSynced) == "pending-activation"' :label='$t(`txt-sync-connection-state-pending`)' :background-class='connectionStatusBackgroundClass("pending-activation")' inline />
                            <StatePill v-if='getConnectionStatus(pod.lastSynced) == "active"' :label='$t("txt-sync-connection-state-active")' :background-class='connectionStatusBackgroundClass("active")' inline />
                            <div v-if='getConnectionStatus(pod.lastSynced) == "expired"'>
                                <tooltip faded>
                                    <template #trigger>
                                        <StatePill
                                            :label='$t("txt-sync-connection-state-expired")'
                                            :background-class='connectionStatusBackgroundClass("expired")'
                                            inline
                                            class='inline-block'
                                        />
                                        <i class='fa fa-info-circle pl-2'></i>
                                    </template>
                                    <template #content>
                                        {{ $t("info-sync-connection-expired") }}
                                    </template>
                                </tooltip>
                            </div>
                        </ListItem>
                        <ListItem>
                            {{ pod.lastSynced ? getLastSync(pod.lastSynced, language) : $t("txt-sync-connection-never-synced") }}
                        </ListItem>
                        <ListItem>
                            {{ pod.userAgent }}
                        </ListItem>
                        <ListItem>
                            <FriendlyButton
                                v-if='getConnectionStatus(pod.lastSynced) == "pending-activation" || getConnectionStatus(pod.lastSynced) == "expired"'
                                extra-small
                                no-margin
                                square
                                label='btn-activate'
                                :action='() => openActivationTokenPopUp(pod)'
                                fullwidth
                            />
                            <FriendlyButton
                                v-if='getConnectionStatus(pod.lastSynced) == "active"'
                                extra-small
                                no-margin
                                square
                                secondary
                                label='btn-get-token'
                                :action='() => openActivationTokenPopUp(pod, true)'
                                fullwidth
                            />
                        </ListItem>
                    </ListRow>
                </template>
            </List>
        </ContentBox>
        <Popup :show='showActivationTokenPopUp' :close='closeActivationTokenPopUpAndRefresh' small>
            <SyncApiActivationTokenPopUp
                :pod-uuid='currPodUuid'
                :pod-name='currPodName'
                :reactivate='reactivate'
                :on-done='closeActivationTokenPopUpAndRefresh'
                :on-error='closeActivationTokenPopUpAndRefresh'
            />
        </Popup>
    </div>
</template>

<script>
    import List from '@/components/List';
    import ListColumn from '@/components/ListColumn';
    import ListRow from '@/components/ListRow';
    import ListItem from '@/components/ListItem';
    import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
    import ContentBox from '@/components/ContentBox';
    import CustomTitle from '@/components/Title';
    import Popup from '@/clientcomponents/Popup.vue';
    import StatePill from '@/components/StatePill.vue';
    import { dateFormat } from '@/filters';
    import { simpleLocale } from '@/i18n';
    import SyncApiActivationTokenPopUp from './SyncApiActivationTokenPopUp.vue';
    import Tooltip from '@/components/Tooltip.vue';

    export default {
        name: 'OrganizationUsersView',
        props: {
            organization: {
                type: Object,
                required: true,
            },
        },
        components: {
            List,
            ListColumn,
            ListRow,
            ListItem,
            FriendlyButton,
            ContentBox,
            CustomTitle,
            StatePill,
            Popup,
            SyncApiActivationTokenPopUp,
            Tooltip,
        },
        data () {
            return {
                loading: false,
                pods: [],
                showActivationTokenPopUp: false,
                currPodUuid: null,
                currPodName: null,
            };
        },
        computed: {
            language () {
                return simpleLocale(this.$i18n.locale);
            },
            downloadLink () {
                return {
                    en: 'https://faq.codabox.com/en/support/solutions/articles/75000085068-codabox-synctool-user-manual#download',
                    fr: 'https://faq.codabox.com/fr/support/solutions/articles/75000085068-codabox-synctool-user-manual#download',
                    nl: 'https://faq.codabox.com/nl/support/solutions/articles/75000085068-codabox-synctool-user-manual#download',
                }[this.language];
            },

        },
        watch: {
            organization: function () {
                this.loading = false;
            },
        },
        // Refresh data every time we navigate to the page
        // mounted () {
        //     this.$emit('update:organization', this.organization);
        //     this.loading = true;
        // },
        mounted () {
            this.openPopupAtLoad();
        },
        methods: {
            dateFormat,
            connectionStatusBackgroundClass (state) {
                switch (state) {
                    case 'active':
                        return 'bg-green-300';
                    case 'pending-activation':
                        return 'bg-grey-300';
                    case 'expired':
                        return 'bg-red-300';
                    default:
                        return 'bg-blue-300';
                }
            },
            getLastSync (podLastSynced, language) {
                return new Date(podLastSynced).toLocaleString(language, {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false,
                });
            },
            getConnectionStatus (podLastSynced) {
              // TODO: return constants iso. strings
                const differenceInMilliseconds = Date.now() - new Date(podLastSynced);
                if (!podLastSynced) {
                    return 'pending-activation';
                } else if (differenceInMilliseconds > 1000  * 60 * 60 * 24 * 30) {
                    return 'expired';
                } else {
                    return 'active';
                }
            },
            openActivationTokenPopUp (pod, reactivate) {
                this.currPodUuid = pod.id;
                this.currPodName = pod.name;
                this.reactivate = reactivate ?? false;
                this.showActivationTokenPopUp = true;
            },
            closeActivationTokenPopUp () {
                this.currPodUuid = null;
                this.currPodName = null;
                this.showActivationTokenPopUp = false;
            },
            async closeActivationTokenPopUpAndRefresh () {
                this.closeActivationTokenPopUp();
                this.$emit('update:organization', this.organization);
                this.loading = true;
            },
            openPopupAtLoad () {
                const queryParams = { ...this.$route.query };
                if (queryParams.openActivationTokenPopUp) {
                    const pod = this.organization?.syncToolConnections.find(connection => connection.id === queryParams.openActivationTokenPopUp);
                    this.openActivationTokenPopUp(pod, false);

                    // clean query param from the url (avoid reopen at refresh or if page is bookmarked)
                    delete queryParams.openActivationTokenPopUp;
                    this.$router.replace({ query: queryParams });
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
.uuid {
    display: inline-block;
    width: 90px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.banner-download {
    background-color: $primary-color;
}
.banner-download h1,p {
    color: white;
}
</style>
