<script>
    export default {
        name: 'CcsTermsDutchView',
        props: {
            isDirectCustomer: Boolean,
        },
    };
</script>

<template>
    <div>
        <h3 class='cb-service-main-title'>
            Beschrijving van de dienst
        </h3>

        <p>
            Dankzij de CARO dienst kan je professionele kredietkaartafschriften rechtstreeks en digitaal aangeleverd krijgen wanneer de titularis en de kaarthouder hun machtiging hiertoe verleend hebben.
            Zo heb je steeds het totaaloverzicht én kan je de individuele transacties makkelijk en correct afpunten.
            Je ontvangt altijd tijdig alle relevante kredietkaartafschriften en je hoeft geen handmatige invoer meer te doen.
        </p>

        <p>
            De CARO-bestanden worden maandelijks aangeleverd, in lijn met de boekingsperiode van de kredietkaartuitgever, indien er gedurende die periode minstens 1 betaling met de kredietkaart is gebeurd.
        </p>

        <p>
            De CARO-bestanden krijgen een logische naam en zijn beschikbaar in een pdf-bestand en in een gestructureerd XML-formaat.
        </p>

        <p>
            CARO is beschikbaar voor Mastercard en VISA kredietkaarten uitgegeven door volgende banken.
            Het aantal ondersteunde banken wordt gaandeweg uitgebreid:
        </p>

        <a href='https://www.codabox.com/nl/caro/' target='_blank'>https://www.codabox.com/nl/caro/ </a>

        <p>
            Via MyCodabox kan je CARO bestellen en de status van je aanvragen opvolgen.
            Om CARO te activeren, moet er voor de onderneming (dossier) minstens één actief CODA-mandaat zijn.
        </p>

        <h3>Tarieven</h3>

        <h4 class='cb-service-title'>
            Administratieve kosten
        </h4>

        <p>
            24 € per klantenreferentie (*) waarvoor CARO geactiveerd werd
        </p>

        <h4 class='cb-service-title'>
            Recurrente kosten
        </h4>

        <table class='table' v-if='isDirectCustomer'>
            <tbody>
                <tr>
                    <td>> 2 actieve CARO klantenreferenties</td>
                    <td>7,53 €/maand/klantenreferentie</td>
                </tr>
                <tr>
                    <td>1 - 2 actieve CARO klantenreferenties</td>
                    <td>9,37 €/maand/klantenreferentie</td>
                </tr>
            </tbody>
        </table>
        <p v-if='isDirectCustomer'>
            Beheer je onder één Codabox-contract meerdere ondernemingen, dan bepaalt het totaal aantal CARO klantenreferenties het tarief voor de recurrente kosten.
        </p>
        <p class='note' v-if='isDirectCustomer'>
            <span class='fa fa-info-circle'></span>
            CARO blijft actief tot je via MyCodabox de dienst archiveert, of het mandaat wordt stopgezet.
        </p>
        <table class='table' v-if='!isDirectCustomer'>
            <tbody>
                <tr>
                    <td>+ 500 klantenreferenties</td>
                    <td>3,62 €/maand/klantenreferentie</td>
                </tr>
                <tr>
                    <td>Tussen 241 en 500 klantenreferenties</td>
                    <td>4,53 €/maand/klantenreferentie</td>
                </tr>
                <tr>
                    <td>Tussen 121 en 240 klantenreferenties</td>
                    <td>5,01 €/maand/klantenreferentie</td>
                </tr>
                <tr>
                    <td>Tussen 61 en 120 klantenreferenties</td>
                    <td>6,28 €/maand/klantenreferentie</td>
                </tr>
                <tr>
                    <td>Tussen 3 en 60 klantenreferenties</td>
                    <td>7,53 €/maand/klantenreferentie</td>
                </tr>
                <tr>
                    <td>Voor 1 en 2 klantenreferenties</td>
                    <td>9,37 €/maand/klantenreferentie</td>
                </tr>
            </tbody>
        </table>

        <p v-if='!isDirectCustomer'>
            Voor de bepaling van het correcte tarief wordt ook rekening gehouden met het aantal geactiveerde diensten voor CODA en VOILA.
            Meer details kan je vinden in de Algemene Voorwaarden, via de link hieronder
        </p>

        <p class='note' v-if='!isDirectCustomer'>
            <span class='fa fa-info-circle'></span>
            Codabox kan niet bepalen wanneer de dienst CODA, CARO of VOILA niet meer nodig is voor een bepaalde klant, kredietkaart of bankrekening,
            bijvoorbeeld wanneer een rekening gesloten wordt of er geen transacties meer op gebeuren. De dienst blijft actief
            (en blijft deel uitmaken van de berekening van de recurrente kosten) tot je deze zelf
            <a href='https://faq.codabox.com/nl/support/solutions/articles/75000087558-hoe-een-dienst-coda-soda-voila-caro-deactiveren-' target='_blank'>via MyCodabox deactiveert</a>.
        </p>

        <p>
            (*) identificatie van de kredietkaart-uitgavenstaat: sommige bankproducten ondersteunen meerdere kaarten op 1 kredietkaart-uitgavenstaat (d.w.z. klantenreferentie).
        </p>

        <h3>Algemene voorwaarden</h3>
        <p v-if='isDirectCustomer'>
            <a href='/static/pdfs/20240901 Overeenkomst CodaBox Business CODA-SODA-CARO_NL.pdf' target='_blank'>Overeenkomst Codabox Business</a>
        </p>
        <p v-else>
            <a href='/static/pdfs/MyCodaBox.com 202401 CB-Fidu NL-Annex CARO (B4-C4-D).pdf' target='_blank'>Algemene voorwaarden van de dienst</a>
        </p>
    </div>
</template>

<style lang='scss' scoped>
    h3.cb-service-main-title {
        margin-top: 10px;
    }
    h4.cb-service-title {
        text-decoration: underline;
    }
    .cb-service-info-highlight {
        font-style: italic;
        font-weight: bold;
    }
    p.note {
        font-style: italic;
    }
    p.note .fa-info-circle {
        font-size: 16px;
    }
    p, table {
        margin: 10px 0;
    }
    table {
        table-layout: fixed;
    }
    tbody {
        color: $primary-color-dark;
    }
</style>
