<script setup>
import { useRouter, useRoute } from 'vue-router';
import { driver } from 'driver.js';
import { useStore } from 'vuex';
import { t } from '@/i18n';
import { useI18n } from 'vue-i18n';

import { watch, computed, onMounted } from 'vue';
import { datadogRum } from '@datadog/browser-rum';
import bus from '@/bus.js';
import _ from 'lodash';
import { apolloClient } from '@/apollo';
import notify from '@/notify';
import { gql } from '@apollo/client/core';

const store = useStore();
const router = useRouter();
const route = useRoute();

// Ids are used to store completion on Cognito custom attributes.
// Please keep them short as custom attributes have a max length.
const ONBOARDING_TUTORIAL_NAME = 'initial';
const ONBOARDING_HELPER_ORG_STEP = 'helper-org';

// Ensure the available tutorials are set before creating any tutorials
store.commit('tutorials/setTutorialsAvailable', {
    tutorialsAvailable: [ONBOARDING_TUTORIAL_NAME, ONBOARDING_HELPER_ORG_STEP],
});

// global object to store driverjs instances
// structure: { tutorialId: { id, routeNameTrigger, startingRoute, driverInstance } }
const tutorialById = {};

// other functionnal variables
let currentOrganizationType = '';
let currentTutorialId = '';
let currentTutorialStep = 1;
let currentTutorialStartTime = 0;

// this help knowing the current tutorial
const currentTutorial = computed(() => {
    return tutorialById[currentTutorialId];
});

const { locale } = useI18n();
watch(locale, () => {
    // on change of locale make sure that tutorial gets recreated with correct translations
    initOnboardingTutorial();
});

const initOnboardingTutorial = function () {
    addTutorial({
        id: ONBOARDING_TUTORIAL_NAME,
        startingRoute: 'dashboard',
        isStateStoredOnUser: true,
        skipable: false,
        closable: false,
        steps: [
            {
                popover: {
                    title: t('initial-onboarding-step-1-ttl', {
                        name: store.state.user.firstName,
                    }),
                    description: t('initial-onboarding-step-1-desc'),
                    showButtons: ['next', 'close'],
                    nextBtnText: t('tutorial-btn-start'),
                },
            },
            {
                element: '.sidenav-companies',
                popover: {
                    description: t('initial-onboarding-step-2-desc'),
                    side: 'right',
                    align: 'center',
                },
            },
            {
                element: '.sidenav-products',
                popover: {
                    description: t('initial-onboarding-step-3-desc'),
                    side: 'right',
                    align: 'center',
                },
            },
            {
                element: '.sidenav-info',
                popover: {
                    description: t('initial-onboarding-step-4-desc'),
                    side: 'right',
                    align: 'center',
                },
            },
            {
                element: '.organization-administration',
                popover: {
                    description: t('initial-onboarding-step-5-desc'),
                    side: 'bottom',
                    align: 'center',
                    skipMissingElement: true,
                },
            },
            {
                element: '.user-menu',
                popover: {
                    description: t('initial-onboarding-step-6-desc'),
                    side: 'bottom',
                    align: 'end',
                },
            },
            {
                element: '.language-switch',
                popover: {
                    description: t('initial-onboarding-step-7-desc'),
                    side: 'bottom',
                    align: 'end',
                },
            },
            {
                popover: {
                    title: t('initial-onboarding-step-8-ttl'),
                    description: t('initial-onboarding-step-8-desc'),
                    nextBtnText: t('tutorial-btn-terminate'),
                },
            },
        ],
        onComplete: async () => {
            if (!store.state.isDirectCustomer) { return; }
            if (store.state.user.environmentIds.length === 0) { return; }

            const fiduciaries = store.state.user.environmentIds;

            try {
                const states = ['active', 'signed', 'sent-hq'];
                const { data } = await apolloClient.query({
                    query: gql`
                        query SearchCodaMandates($input: SearchCodaMandatesInput) {
                            searchCodaMandates(input: $input) {
                                codaMandates {
                                    state
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            paging: { limit: 1 },
                            data: {
                                fiduciaryIds: fiduciaries,
                                states,
                            },
                        },
                    },
                });
                if (data.searchCodaMandates.codaMandates.length === 0) {
                    const environmentId = fiduciaries[0];
                    router.push({ name: 'fidu-client-search', params: { environmentId } });
                }
            } catch (error) {
                notify.error(t('err-unknown'));
            }
        },
    });
};

if (!store.state.flags.notutorial) {
    // this is the general onboarding tutorial
    initOnboardingTutorial();

    // addTutorial({
    //     id: SOME_CONST,
    //     steps: [
    //         {
    //             element: '#id',
    //             popover: {
    //                 title: t(
    //                     'translated-title',
    //                 ),
    //                 description: t(
    //                     'translated-translation',
    //                 ),
    //                 showButtons: ['next', 'close'],
    //             },
    //         },
    //     ],
    // });
}

// addTutorial construct the tutorials object
// TODO: add the possibility to change route between steps
function addTutorial ({
    id, // mandatory, defines what key will be stored on cognito user's attributes
    routeNameTrigger, // optional, trigger a tutorial on certain routes
    startingRoute, // optional, redirect the user to the tutorial starting point
    isStateStoredOnUser = false, // optional, decide if the completion should be persistent on user
    steps, // mandatory, all the tutorial steps, passed on to driver.js
    skipable = true, // optional, programmatically adds a skip button on first step
    closable = true,
    onComplete = () => {},
    onClose = () => {},
    // TODO: add optional persistence (in case we DON'T want to store completion on user)
}) {
    tutorialById[id] = {
        routeNameTrigger,
        startingRoute,
        isStateStoredOnUser,
        steps,
        skipable,
        closable,
        onComplete,
        onClose,
    };

    bus.on(`trigger-tutorial-${id}`, (organizationType = '') => {
        currentOrganizationType = organizationType;
        runTutorial({ id });
    });
}

// runTutorial actually constructs the driver.js object using the currentTutorial object
async function runTutorial ({ id }) {
    // Prevent starting multiple tutorials at once
    if (store.getters['tutorials/tutorialInProgress']) return;

    // Check if this tutorial has been viewed in the current session
    await store.dispatch('tutorials/loadUserTutorials');
    if (store.getters['tutorials/hasSeenTutorial'](id)) return;

    // Track and states
    eventStartTutorial();
    store.commit('tutorials/setTutorialInProgress');
    currentTutorialId = id;
    currentTutorialStep = 1;
    currentTutorialStartTime = performance.now();

    // Check if we need to redirect the user
    if (currentTutorial.value.startingRoute) {
        router.push({ name: currentTutorial.value.startingRoute });
    }

    // Construct driver.js object
    const driverInstance = driver({
        steps: currentTutorial.value.steps,
        showButtons: ['previous', 'next', 'close'],
        nextBtnText: t('tutorial-btn-next'),
        prevBtnText: t('tutorial-btn-previous'),
        doneBtnText: t('tutorial-btn-terminate'),
        allowClose: currentTutorial.value.closable,
        onPopoverRender: (el) => {
            if (currentTutorial.value.skipable) {
                const skipButton = document.createElement('button');
                skipButton.innerText = t('tutorial-btn-skip');
                skipButton.addEventListener('click', () => {
                    driverInstance.destroy();
                });
                el.footerButtons.prepend(skipButton);
            }

            if (!currentTutorial.value.closable) {
                el.closeButton.style.display = 'none';
            }
        },
        onNextClick () {
            currentTutorialStep = driverInstance.getActiveIndex() + 1;
            driverInstance.moveNext();
        },
        onDestroyStarted: () => {
            driverInstance.destroy();
        },
        onDestroyed: async () => {
            store.commit('tutorials/dismissTutorial', { tutorialId: id });
            if (currentTutorial.value.isStateStoredOnUser) {
                await store.dispatch('tutorials/saveUserTutorials');
            }
            if (currentTutorial.value.steps.length === currentTutorialStep) {
                eventTutorialCompleted();
                currentTutorial.value.onComplete();
            } else {
                eventTutorialClosed();
                currentTutorial.value.onClose();
            }
        },
    });

    // Run it
    driverInstance.drive();
}

function eventStartTutorial () {
    datadogRum.addAction('tutorial_started', {
        tutorialId: currentTutorialId,
        userEmail: store.state.user.email,
        orgType: currentOrganizationType,
    });
}

function eventTutorialClosed () {
    datadogRum.addAction('tutorial_closed', {
        tutorialId: currentTutorialId,
        userEmail: store.state.user.email,
        orgType: currentOrganizationType,
        activeStep: currentTutorialStep,
        totalSteps: currentTutorial.value.steps.length,
    });
}

function eventTutorialCompleted () {
    datadogRum.addAction('tutorial_completed', {
        tutorialId: currentTutorialId,
        userEmail: store.state.user.email,
        orgType: currentOrganizationType,
        timeElapsed: (performance.now() - currentTutorialStartTime) / 1000,
    });
}

onMounted(() => {
    // watch routes changes events to trigger specific tutorials
    watch(route, ({ name }) => {
        const instance = _.find(
            tutorialById,
            (tutorial) => tutorial.routeNameTrigger === name,
        );
        if (instance) {
            runTutorial({
                id: instance.id,
            });
        }
    });
});
</script>

<template><template></template></template>
